<template>
  <v-app>
    <LocaleSwitcher />
    <div class="text-center ma-5">
      <h2 class="display-1">{{ $t("sginUp.初めての方へ") }}</h2>
    </div>
    <div class="ma-5">
      {{
        $t(
          "sginUp.クリニックから処方されたコンタクトレンズをネットで注文して自宅でお受け取りいただけます。ご利用には「クリニックcom」のアクセス登録が必要です。登録料やご利用料は必要ありません。"
        )
      }}
      <v-expansion-panels class="mt-5">
        <v-expansion-panel>
          <v-expansion-panel-header>{{
            $t("sginUp.利用規約 プライバシーポリシーを表示")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content v-if="this.privacy">
            <b>{{ $t("sginUp.利用規約") }}</b
            ><br />
            <p v-html="this.term.translated[this.$i18n.locale]"></p>
            <b>{{ $t("sginUp.プライバシーポリシー") }}</b
            ><br />
            <p v-html="this.privacy.translated[this.$i18n.locale]"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-checkbox
        v-model="checkbox"
        id="checkbox_agree"
        :label="$t('sginUp.利用規約に承認して登録を行う')"
      ></v-checkbox>
    </div>
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate>
      </v-progress-circular>
      <br />
      {{ $t("お客様の情報を確認中です。処理が完了するまでお待ちください。") }}
    </div>

    <v-alert
      v-model="alert"
      close-text="Close Alert"
      color="orange accent-4"
      dark
      dismissible
      class="ma-5"
    >
      {{ error_message }}
    </v-alert>
    <v-card-text v-if="login_view == true">
      <div>
        <div id="firebaseui-auth-container"></div>
      </div>
      <div>
        <signin
          v-bind:clinic_id="this.id"
          v-bind:name="this.name"
          v-bind:password="this.password"
          v-bind:phone="this.phone"
          v-bind:to_url="`patient_regster`"
        />
      </div>
    </v-card-text>

    <v-card
      width="95%"
      class="mx-auto"
      v-if="loading === false && this.checkbox == true"
    >
      <v-card-title> {{ $t("sginUp.アカウント登録") }} </v-card-title>
      <v-card-text>
        {{
          $t(
            "sginUp.ご利用の際は、メールアドレス、パスワードを設定してください。"
          )
        }}
        <v-form class="ma-5" id="form_signin">
          <div class="signin">
            <v-text-field
              prepend-icon="mdi-email"
              :label="$t('sginUp.メールアドレス')"
              outlined
              clearable
              v-model="name"
            />
            <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :label="$t('sginUp.パスワード ※6文字以上')"
              outlined
              clearable
              v-model="password"
            />
            {{
              $t(
                "sginUp.※通知をショートメッセージで送信します。受信できる携帯電話番号を登録してください。"
              )
            }}
            <v-text-field
              prepend-icon="mdi-phone"
              :label="$t('sginUp.携帯電話番号(-なし)')"
              placeholder="0901234567"
              outlined
              clearable
              v-model="phone"
            />

            <v-btn
              id="submit"
              class="info"
              @click="doSignup"
              v-if="this.checkbox == true"
              >{{ $t("sginUp.登録") }}</v-btn
            >
            <v-btn
              id="submit"
              depressed
              disabled
              v-if="this.checkbox == false"
              >{{ $t("sginUp.登録") }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "@/config/firebase";
import firebase from "@/config/firebase";
import signin from "./componet_Login.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "login",
  data() {
    return {
      loading: true,
      showPassword: false,
      alert: false,
      checkbox: false,
      login_view: false,
      password: "",
      name: "",
      phone: "",
      user: {},
      error_message: "",
      term: {},
      privacy: {},
    };
  },
  components: {
    LocaleSwitcher,
    signin,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    common() {
      return this.$store.state.commonModule.data["welcome"];
    },
  },
  created: function () {
    if (this.$route.params.id) {
      this.commonFetchById();
    } else {
      this.login();
    }
  },
  methods: {
    commonFetchById: async function () {
      const privacyRef = await Firebase.firestore()
        .collection("translations")
        .doc("Privacy");
      privacyRef.get().then((doc) => {
        if (doc.exists) {
          this.privacy = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No privacy document!");
        }
        this.error = true;
      });
      const termRef = await Firebase.firestore()
        .collection("translations")
        .doc("Term");
      termRef.get().then((doc) => {
        if (doc.exists) {
          this.term = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No term document!");
        }
        this.error = true;
      });
      this.loading = false;
    },
    async doSignup() {
      this.loading = true;
      await firebase
        .signup(this.name, this.password, this.phone)
        .then((result) => {
          // 成功時の処理
          this.loading = false;
          this.$router.push({ path: "/patient_pri/" + this.id });
          return result;
        })
        .catch((err) => {
          this.alert = true; //errorを更新する
          if (
            err.message ==
            "The email address is already in use by another account."
          ) {
            this.error_message =
              "すでに登録済みです。ログインをしていただき、患者様番号登録画面を表示してください。";
            this.checkbox = false;
            this.login_view = true;
          } else if (err.message == "The email address is badly formatted.") {
            this.error_message = "メールアドレスが正しく入力されていません。";
            this.checkbox = false;
            this.login_view = false;
          } else if (
            err.message == "The password must be 6 characters long or more."
          ) {
            this.error_message = "パスワードは6桁の半角英数字が必要です。";
            this.checkbox = false;
            this.login_view = false;
          } else {
            this.error_message = err.message;
            this.checkbox = false;
            this.login_view = false;
          }
          this.loading = false;
        });
    },
    login: function () {
      this.$router.push({ name: "login", params: { id: this.id } });
    },
  },
};
</script>
